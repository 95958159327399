import { default as _91_91page_93_93vx2lMfLOzSMeta } from "/opt/build/repo/frontend/pages/index/[[page]].vue?macro=true";
export default [
  {
    name: _91_91page_93_93vx2lMfLOzSMeta?.name ?? "index-page",
    path: _91_91page_93_93vx2lMfLOzSMeta?.path ?? "//:page?",
    meta: _91_91page_93_93vx2lMfLOzSMeta || {},
    alias: _91_91page_93_93vx2lMfLOzSMeta?.alias || [],
    redirect: _91_91page_93_93vx2lMfLOzSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/frontend/pages/index/[[page]].vue").then(m => m.default || m)
  }
]